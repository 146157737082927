import React from 'react';
import {Helmet} from 'react-helmet';

import '../styles/styles.scss';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = ({children, uri}) => {
    return (
        <>
            <Helmet titleTemplate="%s | skaterhockey.de" defaultTitle="skaterhockey.de">
                <html lang="de"/>
            </Helmet>
            <Header currentUri={uri}/>
            {children}
            <Footer/>
        </>
    );
};

export default Layout;
