import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Container, Nav} from 'react-bootstrap';
import {StaticImage} from 'gatsby-plugin-image';

const StyledFooter = styled.footer`
    background-color: var(--bs-gray-500);
`;

const Icon = styled.i`
    font-size: 40px;
    color: var(--bs-gray-700);

    &:hover {
        color: var(--bs-gray-600);
    }
`;

const Footer = () => (
    <StyledFooter className="py-4">
        <Container>
            <div className="d-flex">
                <Nav className="flex-row">
                    <Nav.Link as={Link} to="/impressum">Impressum</Nav.Link>
                    <Nav.Link as={Link} to="/datenschutz" eventKey="/eltern">Datenschutz</Nav.Link>
                </Nav>
                <div className="flex-fill"/>
                <div className="d-flex align-items-center flex-wrap">
                    <a href="https://www.facebook.com/skaterhockey.de" target="_blank" rel="noreferrer"
                       title="Facebook" className="d-block ms-2">
                        <Icon className="bi bi-facebook"/>
                    </a>
                    <a href="https://www.instagram.com/skaterhockey.de/" target="_blank" rel="noreferrer"
                       title="Instagram" className="d-block ms-2">
                        <Icon className="bi bi-instagram"/>
                    </a>
                    <a href="https://twitter.com/ishd" target="_blank" rel="noreferrer" title="Twitter"
                       className="d-block ms-2">
                        <Icon className="bi bi-twitter"/>
                    </a>
                    <a href="https://www.ishd.de" target="_blank" rel="noreferrer" title="ishd.de"
                       className="d-block ms-2">
                        <StaticImage src="../images/ishd.png" height={40} alt="ishd.de" placeholder="none"/>
                    </a>
                    <a href="https://www.driv.de" target="_blank" rel="noreferrer" title="driv.de"
                       className="d-block ms-2">
                        <StaticImage src="../images/driv.png" height={40} alt="driv.de" placeholder="none"/>
                    </a>
                </div>
            </div>
        </Container>
    </StyledFooter>
);

export default Footer;
