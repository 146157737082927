exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-eltern-js": () => import("./../../../src/pages/eltern.js" /* webpackChunkName: "component---src-pages-eltern-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schulen-und-kindergaerten-js": () => import("./../../../src/pages/schulen-und-kindergaerten.js" /* webpackChunkName: "component---src-pages-schulen-und-kindergaerten-js" */),
  "component---src-pages-spieler-js": () => import("./../../../src/pages/spieler.js" /* webpackChunkName: "component---src-pages-spieler-js" */),
  "component---src-pages-teams-und-vereine-js": () => import("./../../../src/pages/teams-und-vereine.js" /* webpackChunkName: "component---src-pages-teams-und-vereine-js" */)
}

