import React from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const Header = ({currentUri}) => (
    <Navbar bg="primary" variant="dark" expand="lg" sticky="top" collapseOnSelect={true}>
        <Container fluid>
            <div className="d-flex flex-nowrap flex-row justify-content-between flex-fill">
                <Navbar.Brand as={Link} to="/">
                    <StaticImage src="../images/skaterhockey_hash_w_on_r.svg" alt="skaterhockey.de" height={48}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="nav-main"/>
            </div>
            <Navbar.Collapse id="nav-main" className="justify-content-end">
                <Nav activeKey={currentUri}>
                    <Nav.Link as={Link} to="/spieler" eventKey="/spieler">Spieler:innen</Nav.Link>
                    <Nav.Link as={Link} to="/eltern" eventKey="/eltern">Eltern</Nav.Link>
                    <Nav.Link as={Link} to="/teams-und-vereine" eventKey="/teams-und-vereine">Teams & Vereine</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

export default Header;
